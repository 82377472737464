<template>
  <div class="find-on-map">
    <button class="find-on-map__back-btn" type="button" @click.stop="handleClose">
      <TheArrowHorizIcon />
    </button>

    <MapWithEntityDestination
      class="find-on-map__map"
      :latitude="latitude"
      :longitude="longitude"
      :zoom="zoom"
      @load="onMapLoaded"
      @sourcedata="onMapSourceChanged"
    >
      <MglGeojsonLayer
        :layerId="mapCurrentPositionLayer.id"
        :layer="mapCurrentPositionLayer"
        :sourceId="mapCurrentPositionSource.id"
        :source="mapCurrentPositionSource.source"
      />
      <MglGeojsonLayer
        :layerId="mapRouteDestinationLayer.id"
        :layer="mapRouteDestinationLayer"
        :sourceId="mapRouteDestinationSource.id"
        :source="mapRouteDestinationSource.source"
      />
    </MapWithEntityDestination>

    <DestinationMapEntityDetails
      class="find-on-map__details"
      :entity="item"
      @close="handleClose"
      @get-map-direction="drawMapDirection"
      @show-access-instructions="handleShowAccessInstructions"
    >
      <template #see-details-link>
        <button type="button" class="details" @click="closeAndResetScroll">See details</button>
      </template>
    </DestinationMapEntityDetails>

    <MapDrawDirectionErrorModal v-model="drawMapDirectionErrorMsg" />
  </div>
</template>

<script>
import { MglGeojsonLayer } from 'vue-mapbox';
import {
  getMapBounds,
  getMapCurrentPositionSource,
  getMapDirectionFromCurrentPosition,
  getMapRouteDestinationSource,
  MAP_LAYER_CURRENT_POSITION,
  MAP_LAYER_ROUTE_DESTINATION,
} from '@/helpers/mapboxHelper';

import MapWithEntityDestination from '@/components/partials/MapWithEntityDestination';
import MapDrawDirectionErrorModal from '@/components/partials/MapDrawDirectionErrorModal';
import DestinationMapEntityDetails from '@/components/partials/DestinationMapEntityDetails';
import TheArrowHorizIcon from '@/components/icons/TheArrowHorizIcon.vue';

export default {
  name: 'FindOnMap',
  components: {
    TheArrowHorizIcon,
    DestinationMapEntityDetails,
    MapDrawDirectionErrorModal,
    MapWithEntityDestination,
    MglGeojsonLayer,
  },
  props: {
    item: {
      type: Object,
      required: true,
    },
    latitude: {
      type: Number,
      default: 0,
    },
    longitude: {
      type: Number,
      default: 0,
    },
    zoom: {
      type: Number,
      default: 0,
    },
  },
  computed: {
    mapCurrentPositionSource() {
      return getMapCurrentPositionSource(this.mapCurrentPositionSourceCoords);
    },
    mapRouteDestinationSource() {
      return getMapRouteDestinationSource(this.mapRouteDestinationSourceCoords);
    },
  },
  data() {
    return {
      mapCurrentPositionLayer: MAP_LAYER_CURRENT_POSITION,
      mapRouteDestinationLayer: MAP_LAYER_ROUTE_DESTINATION,
      mapCurrentPositionSourceCoords: [],
      mapRouteDestinationSourceCoords: [],
      drawMapDirectionErrorMsg: '',
      drawingMapDirection: false,
    };
  },
  beforeDestroy() {
    this._map = null;
  },
  methods: {
    handleClose() {
      this.$emit('close');
    },
    closeAndResetScroll() {
      window.scrollTo(0, 0);
      this.handleClose();
    },
    handleShowAccessInstructions() {
      this.$emit('show-access-instructions');
    },
    onMapLoaded({ map }) {
      this._map = map;
    },
    onMapSourceChanged({ mapboxEvent: { isSourceLoaded, sourceId } }) {
      if (this.drawingMapDirection && sourceId === this.mapRouteDestinationSource.id && isSourceLoaded) {
        this.drawingMapDirection = false;

        this._map.fitBounds(
          getMapBounds([
            { lng: this.longitude, lat: this.latitude },
            { lng: this.mapCurrentPositionSourceCoords[0], lat: this.mapCurrentPositionSourceCoords[1] },
          ]),
          { padding: 100 }
        );
      }
    },
    drawMapDirection() {
      getMapDirectionFromCurrentPosition({ lat: this.latitude, lng: this.longitude })
        .then(({ currentPosition, routeCoordinates }) => {
          this.drawingMapDirection = true;
          this.mapRouteDestinationSourceCoords = routeCoordinates;
          if (
            !this.mapCurrentPositionSourceCoords.length ||
            (currentPosition[0] !== this.mapCurrentPositionSourceCoords[0] &&
              currentPosition[1] !== this.mapCurrentPositionSourceCoords[1])
          ) {
            this.mapCurrentPositionSourceCoords = currentPosition;
          }
        })
        .catch((err) => {
          this.clearMapDirection();
          this.drawMapDirectionErrorMsg = err.message;
        });
    },
    clearMapDirection() {
      this.mapCurrentPositionSourceCoords = [];
      this.mapRouteDestinationSourceCoords = [];
    },
  },
};
</script>

<style lang="scss" scoped>
.find-on-map {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9999;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100vh;
  overflow: hidden;
  background-color: #fff;

  @media (min-width: 768px) {
    display: block;
    flex-direction: row;
  }

  &__back-btn {
    position: absolute;
    top: 50px;
    left: 25px;
    z-index: 999;
  }

  &__map {
    width: 100%;
    height: calc(100% - 290px);

    @media (min-width: 768px) {
      width: calc(100% - 360px);
      height: 100%;
    }

    @media (min-width: 1200px) {
      width: calc(100% - 600px);
    }
  }

  &__details {
    overflow-x: hidden;

    @media (max-width: 767px) {
      position: static;
      height: 290px;
    }
  }
}
</style>
